<template>
    <div>
        <locality-details-form
            ref="locality"
            :item="item"
            :viewMode="viewMode"
        >
        </locality-details-form>

        <div class="d-flex justify-content-between mt-6">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("APPLICATION.MODALS.CANCEL") }}
            </st-button>
            <st-button size="large" :callback="submit">
                {{ $t("APPLICATION.MODALS.ADD") }}
            </st-button>
        </div>
    </div>
</template>

<script>
import { LocalityModel } from "@/modules/applications/models/locality-model";
import { FormSchema } from "@/shared/form/form-schema";
import { mapGetters } from "vuex";
import LocalityDetailsForm from "@/modules/applications/components/localities/LocalityDetailsForm";
import Message from "@/shared/message/message";

const { fields } = LocalityModel;

const formSchema = new FormSchema([fields.territorial_administrative_unit_id]);

export default {
    name: "LocalityForm",
    components: {
        LocalityDetailsForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            selectedEntity: null
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "applications/form/record",
        }),
    },
    methods: {
        async validateLocality() {
            const fieldRefs = this.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key] && fieldRefs[key].fields
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            const status = await selectedEntity.fv.validate();

            if (selectedEntity.model.county_id !== this.defaultCountyId) {
                const county = selectedEntity.fields.county_id.options.find((el) => el.value === this.defaultCountyId)?.text || "";
                Message.error('REAL_ESTATE.FORM.WRONG_COUNTY', { county });
                return;
            }

            let details = this.fillLocalityDetails(selectedEntity);

            const localityDetails = { ...selectedEntity.model, ...details };

            return {
                status,
                localityDetails,
            };
        },
        fillLocalityDetails(entity) {
            let details = {
                territorial_administrative_unit: entity.fields.territorial_administrative_unit_id.options.find((el) => el.value === entity.model.territorial_administrative_unit_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
            };

            details.village = entity.fields.village_id.options.find((el) => el.value === entity.model.village_id)?.text || "";

            return details;
        },
        cancel() {
            this.$emit('cancelForm');
        },
        async submit() {
            const locality = await this.validateLocality();

            if (locality.status === 'Valid') {
                if (this.item) {
                    locality.index = this.item.index;
                }

                this.$emit("submitForm", locality);
            }
        },
    },
    created() {
        if (this.record?.form?.localities) {
            this.model = formSchema.initialValues(this.record.form.localities);
        } else {
            if (this.item) {
                this.model = formSchema.initialValues(this.item);
            } else {
                const record = {
                    county_id: this.defaultCountyId,
                };

                this.model = formSchema.initialValues(record);
            }
        }
    },
    mounted() {
        this.selectedEntity = this.item ?? null;
    },
};
</script>
