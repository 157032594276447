<template>
    <st-modal
        id="locality-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        :noCloseOnBackdrop="true"
        size="xl"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                {{ $t("LOCALITIES.MODAL.ADD_HEADER") }}
            </p>
        </template>
        <template #body>
            <locality-form
                :item="item"
                @submitForm="submit"
                @cancelForm="cancel"
            ></locality-form>
        </template>
    </st-modal>
</template>

<script>
import LocalityForm from "@/modules/applications/components/localities/LocalityForm";

export default {
    name: "LocalityModal",
    props: {
        item: {
            type: Object,
            required: false,
        },
    },
    components: {
        LocalityForm,
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        submit(data) {
            this.$emit("processLocality", data);
            this.hide();
        },
        cancel() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
