<template>
    <form class="form" novalidate="novalidate" id="st_locality_details_form">
        <div class="row">
            <div class="col-2 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.territorial_administrative_unit_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.territorial_administrative_unit_id.name]"
                            :ref="fields.territorial_administrative_unit_id.name"
                            :name="fields.territorial_administrative_unit_id.name"
                            :options="fields.territorial_administrative_unit_id.options"
                            :disabled="viewMode"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.village_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.village_id.name]"
                            :options="fields.village_id.options"
                            :ref="fields.village_id.name"
                            :name="fields.village_id.name"
                            :disabled="!fields.village_id.options.length || viewMode"
                            :model="model"
                            :fields="fields"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.street.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.street.name"
                            :name="fields.street.name"
                            v-model="model[fields.street.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.street_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.street_number.name"
                            :name="fields.street_number.name"
                            v-model="model[fields.street_number.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.block.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.block.name"
                            :name="fields.block.name"
                            v-model="model[fields.block.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.staircase.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.staircase.name"
                            :name="fields.staircase.name"
                            v-model="model[fields.staircase.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.floor.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.floor.name"
                            :name="fields.floor.name"
                            v-model="model[fields.floor.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.apartment.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.apartment.name"
                            :name="fields.apartment.name"
                            v-model="model[fields.apartment.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { LocalityModel } from "@/modules/applications/models/locality-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters, mapActions } from "vuex";

const { fields } = LocalityModel;

const formSchema = new FormSchema([
    fields.id,
    fields.county,
    fields.county_id,
    fields.territorial_administrative_unit,
    fields.territorial_administrative_unit_id,
    fields.village,
    fields.village_id,
    fields.street,
    fields.street_number,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
]);

export default {
    name: "LocalityDetailsForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
        }),
        hasUatSelected() {
            return !!this.model[this.fields.territorial_administrative_unit_id.name];
        },
    },
    methods: {

    },
    async created() {
        //hardcode Cluj as single option for county
        this.fields.county_id.options = this.fields.county_id.options.filter(
            (el) => {
                return el.value === this.defaultCountyId;
            }
        );
        if (this.item) {
            this.model = formSchema.initialValues(this.item);
        } else {
            const record = {
                county_id: this.defaultCountyId,
            };
            this.model = formSchema.initialValues(record);
        }
    },
    watch: {},
    mounted() {
        this.fv = createFormValidation("st_locality_details_form", this.rules);
    },
    beforeDestroy () {},
};
</script>
