<template>
    <div>
        <div class="row">
            <div class="col col-8 d-flex align-items-center">
                <!-- <div class="font-weight-bold">{{ item.territorial_administrative_unit_id }}</div> -->
            </div>
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("LOCALITIES.CARD.UAT") }}: </span>
            <span>{{ item.territorial_administrative_unit }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("LOCALITIES.CARD.VILLAGE") }}: </span>
            <span>{{ item.village }}</span>
        </div>
        <div class="mb-5">
            <div class="font-weight-bold">{{ $t("LOCALITIES.CARD.ADDRESS") }}:</div>
            <div>{{ addressComputed }}</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'LocalityItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        addressComputed() {
            let address = '';
            if (this.item.county) address += `${this.$t('BENEFICIARY.CARD.ADDRESS_COUNTY')} ${this.item.county}`;
            if (this.item.country) address += this.item.country;
            if (this.item.locality) address +=  `, ${this.item.locality}`;
            if (this.item.street) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_STREET')} ${this.item.street}`;
            if (this.item.street_number) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_NUMBER')} ${this.item.street_number}`;
            if (this.item.staircase) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_STAIRCASE')} ${this.item.staircase}`;
            if (this.item.apartment) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_APARTMENT')} ${this.item.apartment}`;
            return address;
        },
    }
}
</script>
